import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="753595080102-h6sj78cc13i0oe0h4hulnb3bs5r5rqj6.apps.googleusercontent.com">
    <App />
    </GoogleOAuthProvider>
  </React.StrictMode>

);


reportWebVitals();
